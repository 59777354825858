<template>
  <div class="container-fluid" style="padding-left: 0px; padding-right: 0px;">
    <div class="row">
      <div class="col-sm-12">
        <div id="resultsHeader">
          <div class="checkboxCol"></div>
          <div class="normalCol">Stocktake No</div>
          <div class="trippleCol">Count Category</div>
          <div class="normalCol">Status</div>
          <div class="doubleCol">Assigned To</div>
        </div>
      </div>
    </div>

    <div class="row" v-for="category in categories" :key="category.id">
      
        <div v-if="category.status==='CompletedA' && stocktake.status !='CompletedA'" class="col-sm-12">

        </div>
        <div v-else class="col-sm-12">
          <div id="resultsBody">
            <div class="checkboxCol"></div>
            <router-link :to="{name: 'StockTakeCategoryView', params: { id: category.id }}">
            <div class="normalCol">{{ category.stocktake_no }}</div>
            <div class="trippleCol">{{ category.name }}</div>
            <div class="normalCol"><span class="itemStatus" :class="category.status">{{ category.status +' '+category.recount_number }}</span></div>
            </router-link>
            <div class="doubleCol">
              <div class="commerceSelect">
                <select v-model="category.assigned_to" @change="onAssignedChange(category.id, category.assigned_to)">
                  <option value="">Team Member</option>
                  <option value="adam">Adam</option>
                  <option value="ashley">Ashley</option>
                  <option value="finn">Finn</option>
                  <option value="greg">Greg</option> 
                  <option value="jacob">Jacob</option>
                  <option value="javier">Javier</option>
                  <option value="joanne">Joanne</option>
                  <option value="kasha">Kasha</option>
                  <option value="laura">Laura</option>
                  <option value="mark">Mark</option>
                  <option value="miles">Miles</option>
                  <option value="naomi">Naomi</option>
                  <option value="richard">Richard</option>
                  <option value="samantha">Samantha</option>
                  <option value="shane">Shane</option>
                </select>
              </div>
            </div>
          </div>
        </div>
    </div>

    <div class="row" v-if="newcategory">
      <div class="col-sm-12">
        <div id="resultsBody">
          <div class="checkboxCol"></div>
          <div class="normalCol"></div>
          <div class="trippleCol">
            <div class="commerceSelect">
                <select v-model="newcat.unleashed_guid" @change="onCategoryChange()">
                  <option value="">Select Category</option>
                  <option v-for="unleashedcategory in unleashedcategories" :key="unleashedcategory.Guid" :value="unleashedcategory.Guid">{{ unleashedcategory.StockTakeName }}</option>
                </select>
              </div>
          </div>
          <div class="normalCol"></div>
          <div class="doubleCol"></div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div align="center">
        <div class="addNewItemLink" @click="newcategory = !newcategory" style="margin-top: 15px;">
          <font-awesome-icon icon="plus" /> Add another category
        </div>
        </div>  
      </div>
    </div>

    <div class="row" style="margin-top: 30px; margin-bottom: 30px;">
      <div class="col-sm-1">
        
      </div>
      <div class="col-sm-10">
        <div class="row">
          <div class="col-sm-4">
            <div class="pageCard greenTop">
              <div class="row">
                <div class="col-sm-12">
                  <h6>Progress</h6>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  {{ stocktakestats.not_counted }}%
                </div>
                <div class="col-sm-9">
                  Not counted
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  {{ stocktakestats.in_count }}%
                </div>
                <div class="col-sm-9">
                  In Count
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  {{ stocktakestats.recounting }}%
                </div>
                <div class="col-sm-9">
                  Recounting
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  {{ stocktakestats.reconciling }}%
                </div>
                <div class="col-sm-9">
                  Reconciling
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  {{ stocktakestats.completed }}%
                </div>
                <div class="col-sm-9">
                  Completed
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">

          </div>
          <div class="col-sm-4">
            <div class="pageCard greenTop">
              <div class="row">
                <div class="col-sm-12">
                  <h6>Timings</h6>
                </div>
              </div>
              <!--<div class="row">
                <div class="col-sm-6">
                  Start at:
                </div>
                <div class="col-sm-6">

                </div>
              </div>-->
              <div class="row">
                <div class="col-sm-6">
                  Currently taken:
                </div>
                <div class="col-sm-6">
                  {{ countdown(this.now, Date.parse(stocktake.created_at)) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-1">

      </div>
    </div>

  </div>
</template>


<script>

import axios from 'axios'
import moment from 'moment'

export default {
  components: {  },
  data () {
    return {
      interval: null,
      now: new Date(),
      categories: [],
      stocktake: [],
      newcategory: false,
      newcat: [],
      unleashedcategories: [],
      stocktakestats: [],
    }
  },
  created () {
    axios
      .get(`/stocktakes/${this.$route.params.id}`)
      .then(response => {
        this.stocktake = response.data
      })
    axios
      .get(`/stocktakes/${this.$route.params.id}/categories`)
      .then(response => {
        this.categories = response.data
      })
    axios
      .get(`/stocktakes/unleashed-categories`)
      .then(response => {
        this.unleashedcategories = response.data.Items
      })
    axios
      .get(`/stocktakes/${this.$route.params.id}/stats`)
      .then(response => {
        this.stocktakestats = response.data
      })
  },
  methods: {
    deleteEvent: function(index) {
      this.unleashedcategories.splice(index, 1);
    },
    refreshCount () {
      axios
        .get(`/stocktakes/${this.$route.params.id}`)
        .then(response => {
          this.stocktake = response.data
        })
      axios
        .get(`/stocktakes/${this.$route.params.id}/categories`)
        .then(response => {
          this.categories = response.data
        })
    },
    addNewItem () {
      this.categories.push({})
    },
    onAssignedChange(category, assigned) {
      const postcategory = {
        assigned_to: assigned,
      }
      console.log(postcategory)
      axios
        .post(`/stocktakes/categories/${category}`, postcategory)
        .then(response => (
          this.$toast.success("Category Assigned", {})
        ))
        .catch(err => console.log(err))
        // .finally(() => this.loading = false)
    },
    onCategoryChange() {
      const postcategory = {
        unleashed_guid: this.newcat.unleashed_guid,
        stocktake_id: this.$route.params.id
      }
      axios
        .post(`/stocktakes/${this.$route.params.id}/categories`, postcategory)
        .then(response => (
          this.refreshCount(),
          //this.$router.push({ name: 'StockTakeView', params: { id: this.$route.params.id } }),
          this.$toast.success("Category Added", {})
        ))
        .catch(err => console.log(err))
        // .finally(() => this.loading = false)
    },
    countdown(now, then) {
      return 0 < then - now 
        // If *then* is in the future, return a positive countdown (counting down)
        ? moment.utc(then - now).format('HH:mm:ss')
        // If *then* is in the past, return a negative countdown (counting up negative values)
        : '' + moment.utc(now - then).format('HH:mm:ss')  
    }
  },
  mounted() {
    this.interval = setInterval(() => {
       this.now = new Date()
    }, 1000)
  }
}
</script>