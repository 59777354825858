<template>
<div>
  <div class="container-fluid" style="padding-left: 0px; padding-right: 0px;">
    <header class="titleRow">
      <div class="row">
        <div class="col-sm-4">
            <h4>Wholesale</h4>
        </div>
        <div class="col-sm-5">
          
        </div>
        <div class="col-sm-2">
        
        </div>
        <div class="col-sm-1">
           
        </div>
      </div>
    </header>
    <div class="pageContainer">
      <div class="row">
        <div class="col-sm-9">
          <div class="pageSubContainer">
            <div class="row">
              <div class="col-sm-1"></div>
              <div class="col-sm-10">
                <div class="row">
                  <div class="col-sm-12">
                    <h2>Campaign Page</h2>
                  </div>
                </div>
                <form @submit.prevent="saveCampaign">
                
                <div class="row">
                  <div class="col-sm-4">
                    <label>Enabled</label>
                  </div>
                  <div class="col-sm-8">
                    <label class="checkboxContainer">
                      <input type="checkbox" v-model="campaign.enabled" value="1">
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>   
                <div class="row">
                  <div class="col-sm-4">
                    <label>Campaign Name</label>
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="text" v-model="campaign.title" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Products Included</label>
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="text" v-model="campaign.products" />
                  </div>
                </div>
                <div class="row" style="margin-top:30px;">
                  <div class="col-sm-12">
                    <hr />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    
                  </div>
                  <div class="col-sm-8">
                    <button type="submit" name="submit">Save</button>
                  </div>
                </div>
                </form>
              </div>
              <div class="col-sm-1"></div>
            </div>
          </div>
        </div>
        <div class="col-sm-3"></div>
      </div>
    </div>
  </div>
  
</div>
</template>

<script>

import axios from 'axios'

export default {
  data () {
    return {
      campaign: []
    }
  },
  
  created () {
    axios
      .get(`/wholesaleplatform/campaign`)
      .then((res) => {
        this.campaign = res.data
      })
  },
  methods: {
    saveCampaign () {
      axios
        .put(`/wholesaleplatform/campaign`, this.campaign)
        .then(response => (
          this.$router.push({ name: 'WholesaleCampaign' })
        ))
        .catch(err => console.log(err))
        // .finally(() => this.loading = false)
        this.$toast.success("Campaign saved", {});  
    }
  }
}
</script>
